import type { JSX } from "solid-js/jsx-runtime";

type Props = JSX.ButtonHTMLAttributes<HTMLButtonElement>;

export const Pill = (props: Props) => (
  <button
    onclick={props.onclick ?? (() => {})}
    type="button"
    class="rounded-full lg:hover:scale-105 font-bold bg-white active:bg-opacity-90 transition text-black bg-opacity-70 shadow uppercase text-xs py-1 px-2"
  >
    {props.children}
  </button>
);
