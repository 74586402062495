export async function* generateString(string: string) {
  const words = string.split(" ");
  let current = 0;
  while (current < words.length) {
    const timeout = Math.floor(Math.random() * (50 - 0 + 1)) + 0;
    await new Promise((resolve) => setTimeout(resolve, timeout));
    yield words[current] + " ";
    current += 1;
  }
}
