import type { JSX } from "solid-js/jsx-runtime";

type Props = {
  type?: "button" | "submit";
  onclick?: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>;
  children: JSX.Element;
} & JSX.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = (props: Props) => (
  <button
    type={props.type || "button"}
    onclick={props.onclick || (() => {})}
    class={
      props.class ||
      "rounded-md flex items-center justify-center text-white p-2 font-bold bg-black bg-opacity-35"
    }
    disabled={props.disabled || false}
  >
    {props.children}
  </button>
);
