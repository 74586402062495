import type { JSX } from "solid-js/jsx-runtime";

type Props = {
  value: string;
  oninput: JSX.InputEventHandlerUnion<HTMLInputElement, InputEvent>;
  placeholder: string;
  ref: HTMLInputElement | null;
};

export const Input = (props: Props) => (
  <input
    ref={props.ref!}
    class="bg-transparent text-base border text-black bg-white rounded p-2 w-full"
    type="text"
    value={props.value}
    onInput={props.oninput}
    placeholder={props.placeholder}
  />
);
